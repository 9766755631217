import React from 'react';
import { BrowserRouter } from 'react-router-dom';

interface AppProvidersProps {
  children: React.ReactNode;
}

export function AppProviders({ children }: AppProvidersProps) {
  return (
    <React.StrictMode>
      <BrowserRouter future={{ v7_startTransition: true }}>
        {children}
      </BrowserRouter>
    </React.StrictMode>
  );
}
