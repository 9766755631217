import React, { useState } from "react";
import { passwordlessAuthService } from "../services/firebase";

interface SignInModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SignInModal: React.FC<SignInModalProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setMessage("");
    setIsLoading(true);

    try {
      console.log("Attempting to send sign-in link to:", email);
      await passwordlessAuthService.sendSignInLink(email);
      console.log("Sign-in link sent successfully");
      setMessage("Check your email for the sign-in link. You can close this window.");
      // Don't close the modal automatically - let the user read the message
    } catch (err) {
      console.error("Error sending sign-in link:", err);
      setError(
        err instanceof Error
          ? err.message
          : "Failed to send sign-in link. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-md w-96">
        <h2 className="text-lg font-semibold mb-4">Sign In</h2>
        {message && (
          <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded mb-4">
            {message}
          </div>
        )}
        {error && (
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded mb-4">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="w-full p-2 border border-gray-300 rounded mb-4 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
            required
            disabled={isLoading}
          />
          <button
            type="submit"
            className={`w-full ${
              isLoading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-emerald-600 hover:bg-emerald-500"
            } text-white p-2 rounded transition-colors duration-200`}
            disabled={isLoading}>
            {isLoading ? "Sending..." : "Send Sign-In Link"}
          </button>
        </form>
        <button
          onClick={onClose}
          className="mt-4 text-sm text-gray-500 hover:underline"
          disabled={isLoading}>
          Close
        </button>
      </div>
    </div>
  );
};

export default SignInModal;
