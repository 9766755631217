// src/hooks/useAuth.ts
import { useState, useEffect } from "react";
import { User } from "firebase/auth";
import { authService } from "../services/firebase";

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    console.log("Setting up auth state listener in useAuth");
    const unsubscribe = authService.onAuthStateChanged((user) => {
      console.log("Auth state changed in useAuth hook:", user?.email);
      setUser(user);
      setLoading(false);
    });

    return () => {
      console.log("Cleaning up auth state listener in useAuth");
      unsubscribe();
    };
  }, []);

  const signOut = async () => {
    try {
      setError(null);
      await authService.signOut();
      console.log("User signed out successfully");
    } catch (err) {
      console.error("Error signing out:", err);
      setError(err instanceof Error ? err : new Error("Failed to sign out"));
    }
  };

  return {
    user,
    loading,
    error,
    signOut,
  };
}
