import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { passwordlessAuthService } from "../services/firebase";
import { isSignInWithEmailLink, getAuth } from "firebase/auth";

const EmailSignIn: React.FC = () => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const handleEmailLink = async () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          // If missing email, prompt user for it
          email = window.prompt('Please provide your email for confirmation');
        }

        try {
          if (email) {
            await passwordlessAuthService.signInWithEmailLink(email, window.location.href);
            window.localStorage.removeItem('emailForSignIn');
            setMessage("Successfully signed in!");
            // Redirect to home page after successful sign-in
            setTimeout(() => navigate('/'), 1500);
          }
        } catch (err) {
          console.error("Error signing in:", err);
          setError("Failed to sign in. Please try again.");
        }
      }
    };

    handleEmailLink();
  }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded shadow-md w-96">
        <h2 className="text-lg font-semibold mb-4">Email Sign-In</h2>
        {message && <p className="text-green-500 mb-4">{message}</p>}
        {error && <p className="text-red-500 mb-4">{error}</p>}
      </div>
    </div>
  );
};

export default EmailSignIn;
