// src/services/firebase/index.ts
import {
  getAuth,
  signInWithRedirect,
  signOut,
  GoogleAuthProvider,
  onAuthStateChanged,
  User,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  ActionCodeSettings,
  browserLocalPersistence,
  setPersistence,
  connectAuthEmulator,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  query,
  orderBy,
  onSnapshot,
  QuerySnapshot,
  DocumentData,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDyNwVwaDSvYDlIZBWnIey2uEsusju1W_4",
  authDomain: "cheatsheet-2e1ed.firebaseapp.com",
  databaseURL: "https://cheatsheet-2e1ed.firebaseio.com",
  projectId: "cheatsheet-2e1ed",
  storageBucket: "cheatsheet-2e1ed.appspot.com",
  messagingSenderId: "360890087425",
  appId: "1:360890087425:web:f8e1723b7927d7e11e6800",
  measurementId: "G-DS9DGZMRHQ",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);

// Connect to auth emulator in development
if (window.location.hostname === 'localhost') {
  console.log('Using auth emulator');
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
  connectFirestoreEmulator(db, 'localhost', 8080);
}

// Set persistence to LOCAL
setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error("Error setting persistence:", error);
});

const actionCodeSettings: ActionCodeSettings = {
  url: window.location.hostname === 'localhost' 
    ? 'http://localhost:5000' // Emulator hosting port
    : 'http://localhost', // Production URL
  handleCodeInApp: true,
};

// Types
export interface UrlData {
  id: string;
  url: string;
  name?: string;
  order: number;
}

export interface ScratchpadData {
  text: string;
  timestamp: Date;
}

export interface WeatherWidgetStatus {
  enabled: boolean;
  lastUpdated: Date;
}

// Auth Service
export const authService = {
  currentUser: () => auth.currentUser,

  onAuthStateChanged: (callback: (user: User | null) => void) => {
    console.log("Setting up auth state change listener");
    return onAuthStateChanged(auth, (user) => {
      console.log("Auth state changed - Current user:", user?.email);
      callback(user);
    });
  },

  signInWithGoogle: async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });
    return signInWithRedirect(auth, provider);
  },

  signOut: () => signOut(auth),
};

// Passwordless Auth Service
export const passwordlessAuthService = {
  sendSignInLink: async (email: string) => {
    try {
      console.log("Sending sign-in link to:", email);
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      console.log("Sign-in link sent successfully");
      return true;
    } catch (error) {
      console.error("Error sending sign-in link:", error);
      throw error;
    }
  },

  signInWithEmailLink: async (email: string, emailLink: string) => {
    try {
      console.log("Attempting to sign in with email link");
      if (!isSignInWithEmailLink(auth, emailLink)) {
        throw new Error("Invalid sign-in link");
      }
      const result = await signInWithEmailLink(auth, email, emailLink);
      console.log("Sign-in successful:", result.user?.email);
      return result;
    } catch (error) {
      console.error("Error signing in with email link:", error);
      throw error;
    }
  },
};

// URL Service
export const urlService = {
  // Subscribe to URL changes
  subscribeToUrls: (userId: string, callback: (urls: UrlData[]) => void) => {
    const urlsRef = collection(db, `users/${userId}/urls`);
    const q = query(urlsRef, orderBy("order", "asc"));

    return onSnapshot(q, (snapshot: QuerySnapshot<DocumentData>) => {
      const urls = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as UrlData[];
      callback(urls);
    });
  },

  // Add new URL
  addUrl: async (userId: string, urlData: Omit<UrlData, "id">) => {
    const urlsRef = collection(db, `users/${userId}/urls`);
    const newDoc = doc(urlsRef);
    await setDoc(newDoc, urlData);
    return newDoc.id;
  },

  // Update URL
  updateUrl: async (
    userId: string,
    urlId: string,
    urlData: Partial<UrlData>
  ) => {
    const urlRef = doc(db, `users/${userId}/urls/${urlId}`);
    await setDoc(urlRef, urlData, { merge: true });
  },

  // Delete URL
  deleteUrl: async (userId: string, urlId: string) => {
    const urlRef = doc(db, `users/${userId}/urls/${urlId}`);
    await deleteDoc(urlRef);
  },

  // Update URL order
  updateUrlOrder: async (userId: string, urlId: string, newOrder: number) => {
    const urlRef = doc(db, `users/${userId}/urls/${urlId}`);
    await setDoc(urlRef, { order: newOrder }, { merge: true });
  },
};

// Scratchpad Service
export const scratchpadService = {
  // Get scratchpad content
  getScratchpad: async (userId: string): Promise<ScratchpadData | null> => {
    const scratchpadRef = doc(db, `users/${userId}/notes/scratchpad`);
    const docSnap = await getDoc(scratchpadRef);
    return docSnap.exists() ? (docSnap.data() as ScratchpadData) : null;
  },

  // Update scratchpad
  updateScratchpad: async (userId: string, text: string) => {
    const scratchpadRef = doc(db, `users/${userId}/notes/scratchpad`);
    await setDoc(scratchpadRef, {
      text,
      timestamp: new Date(),
    });
  },

  // Clear scratchpad
  clearScratchpad: async (userId: string) => {
    const scratchpadRef = doc(db, `users/${userId}/notes/scratchpad`);
    await deleteDoc(scratchpadRef);
  },
};

// Weather Widget Service
export const weatherWidgetService = {
  // Get widget status
  getWidgetStatus: async (
    userId: string
  ): Promise<WeatherWidgetStatus | null> => {
    const userRef = doc(db, `users/${userId}`);
    const docSnap = await getDoc(userRef);
    return docSnap.exists()
      ? {
          enabled: docSnap.data().weatherWidgetStatus || false,
          lastUpdated:
            docSnap.data().weatherWidgetLastUpdated?.toDate() || new Date(),
        }
      : null;
  },

  // Update widget status
  updateWidgetStatus: async (userId: string, enabled: boolean) => {
    const userRef = doc(db, `users/${userId}`);
    await setDoc(
      userRef,
      {
        weatherWidgetStatus: enabled,
        weatherWidgetLastUpdated: new Date(),
      },
      { merge: true }
    );
  },
};

export { auth, db, analytics };
