import React from "react";
import { Routes, Route } from "react-router-dom";
import Auth from "./components/Auth";
import EmailSignIn from "./pages/EmailSignIn";
import { useAuth } from "./hooks/useAuth";
import Banner from "./components/Banner";
import UrlManager from "./components/UrlManager";
import AdminInterface from "./components/AdminInterface";
import "./styles/App.css";

function App() {
  const { user } = useAuth();
  console.log("App rendering, user:", user?.email);

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8"
          aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <h1 className="block text-3xl font-logo font-bold tracking-tight">
                start
              </h1>
            </a>
          </div>
          <div className="hidden lg:flex lg:gap-x-12"></div>
          <div className="flex flex-1 items-center justify-end gap-x-6">
            <Auth />
          </div>
        </nav>
      </header>

      <main className="container mx-auto px-4 py-8">
        <Routes>
          <Route
            path="/"
            element={
              user ? (
                <div className="mx-auto max-w-7xl p-6 lg:px-8">
                  <Banner />
                  <UrlManager />
                  <div className="mt-8">
                    <AdminInterface />
                  </div>
                </div>
              ) : (
                <div className="max-w-7xl mx-auto">
                  <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-8 px-4 sm:pt-8 sm:px-6 lg:px-8">
                      <div className="text-left">
                        <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                          A helpful place to start your day
                        </p>
                        <p className="max-w-xl mt-5 text-left text-xl text-gray-500">
                          Add sites and apps you visit frequently and access them
                          easily from any device.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          />
          <Route path="/email-signin" element={<EmailSignIn />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
