// src/components/Auth.tsx
import React, { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import SignInModal from "./SignInModal";

function Auth() {
  const { user, loading, error, signOut } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    console.log("Opening sign-in modal");
    setModalOpen(true);
  };
  
  const closeModal = () => {
    console.log("Closing sign-in modal");
    setModalOpen(false);
  };

  if (loading) {
    return (
      <button className="relative inline-flex items-center gap-x-1.5 rounded-md bg-slate-200 px-3 py-2 text-sm font-normal text-slate-800">
        Loading...
      </button>
    );
  }

  return (
    <div className="flex items-center">
      {error && (
        <div className="text-red-500 text-sm mr-4" role="alert">
          {error.message}
        </div>
      )}
      <div className="flex-shrink-0">
        {user ? (
          <div className="flex items-center">
            <span className="mr-2 text-sm text-gray-700">{user.email}</span>
            <button
              type="button"
              className="relative inline-flex items-center gap-x-1.5 rounded-md bg-slate-200 px-3 py-2 text-sm font-normal text-slate-800 shadow-sm hover:bg-rose-200"
              onClick={signOut}>
              Sign out
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              onClick={openModal}
              className="relative inline-flex items-center gap-x-1.5 rounded-md bg-emerald-600 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-emerald-500">
              Sign In
            </button>
            <SignInModal isOpen={isModalOpen} onClose={closeModal} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Auth;
